@font-face {
    font-family: 'Roboto';
    src: url('./assets/Roboto/Regular.ttf');
}

@font-face {
    font-family: 'RobotoLight';
    src: url('./assets/Roboto/Light.ttf');
}

body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F6F6F6;
}

.d-flex {
    display: flex;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.ml-auto {
    margin-left: auto;
}

.mr-15px {
    margin-right: 15px;
}

.mb-5px {
    margin-bottom: 5px;
}

.my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-muted {
    opacity: .5;
}

.header {
    header {
        display: flex;
        height: 50px;
        padding: 15px 30px;
        align-items: center;
        background-color: white;

        .list-nav, .open-list-nav {
            display: none;
        }

        @media screen and (max-width: 1000px) {
            .default-nav {
                display: none;
            }

            .list-nav {
                z-index: 10;
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.4);
                nav {
                    background-color: white;
                    float: right;
                    display: flex;
                    flex-direction: column;
                    width: 70%;
                    max-width: 400px;
                    height: 100%;

                    .nav-link {
                        // background: none;
                        text-align: right;
                        margin: 10px 25px;
                    }

                    .close-list-nav {
                        width: 50px;
                        background: none;
                        text-align: right;
                        margin: 10px 25px 20px auto;
                        font-size: 20px;
                        color: #545454;
                    }
                }
            }

            .show-list-nav {
                display: block;
            }

            .open-list-nav {
                display: block;
                color: #545454;
                background: none;
                font-size: 20px;
                padding: 0;
                margin-left: 20px;
            }
        }

        .nav-link {
            text-decoration: none;
            color: #545454;
            font-size: 16px;
            margin-right: 15px;
            padding: 10px 20px;
            border-radius: 6px;

            &:hover {
                background-color: #F1F6FC;
                color: #65AAFF;
            }
        }

        .logo {
            height: 40px;
            margin-right: 30px;

            img {
                height: 100%;
            }
        }
    }

    .sub-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        background-color: white;
        font-size: 14px;
        color: #474747;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .1);

        .actions {
            .btn {
                margin-left: 10px;
            }
        }

        @media screen and (max-width: 740px) {
            & {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 0;

                span {
                    margin-bottom: 10px;
                }
            }

            .actions {
                text-align: right;
            }
        }
    }
}

.btn {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: inherit;
    text-decoration: none;

    .svg-inline--fa {
        margin-right: 6px;
    }
}

.btn-light-primary {
    position: relative;
    background-color: #F1F6FC;
    color: #65AAFF;

    &:hover {
        background-color: #65AAFF;
        color: white;
    }
}

.header header .btn-nav-active {
    background-color: #F1F6FC;
    color: #65AAFF;
}

.btn-primary {
    background: #65AAFF;
    color: white;

    &:hover {
        background-color: #4e98f2;
    }
}

.btn-rounded {
    border-radius: 25px;
}

.btn-outline-danger {
    background: none;
    border: 1px solid #FF5757;
    color: #FF5757;
    outline: none;

    &:hover {
        background: #FF5757;
        color: white;
    }

    &:focus {
        background: #ff7979;
        color: white;
    }
}

.btn-outline-primary {
    background: none;
    border: 1px solid #65AAFF;
    color: #65AAFF;
    outline: none;

    &:hover {
        background: #65AAFF;
        color: white;
    }

    &:focus {
        background: #65AAFF;
        color: white;
    }
}

.btn-outline-secondary {
    background: none;
    border: 1px solid #c5c5c5;
    color: #c5c5c5;
    outline: none;

    &:hover {
        background: #c5c5c5;
        color: white;
    }

    &:focus {
        background: #c5c5c5;
        color: white;
    }
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);
    padding: 15px;
}

main {
    padding: 30px;
}

.table {
    width: 100%;
    border-collapse: collapse;

    thead
    {
        th {
            text-align: left;
            color: #474747;
            font-weight: 100;
            font-size: 16px;
            padding-bottom: 10px;
        }

        tr {
            border-bottom: 1px solid rgba(112, 112, 112, 0.693);
        }
    }

    tr:not(:last-child) {
        border-bottom: .5px solid rgba(112, 112, 112, 0.404);
    }
    tbody {
        tr {
            height: 60px;
        }
    }

    tr {
        td {
            font-size: 14px;
            font-family: 'RobotoLight';
            color: #474747;
            padding: 10px 0;

            .btn {
                margin-right: 10px;
            }
        }
    }

}

.roboto-light {
    font-family: 'RobotoLight';
}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .4);

    color: #474747;

    .modal-header {
        display: flex;
        h2 {
            margin: 0 0 5px;
            font-size: 16px;
        }

        span {
            font-size: 14px;
            opacity: .7;
            font-family: 'RobotoLight';
        }
    }

    .modal-card {
        padding: 30px;
        border-radius: 10px;
        background: white;

        .modal-type-copy {
            display: flex;
            margin-top: 40px;

            input {
                border-radius: 10px;
                border: none;
                margin-right: 10px;
                flex-grow: 1;
                padding: 15px;
                font-size: 16px;
                color: #8E8E8E;
            }
        }
    }

    .btn-modal-close {
        background: none;
        padding: 0;
        font-size: 24px;
        opacity: .6;
        margin-left: 50px;
        height: 24px;

        &:hover {
            opacity: 1;
        }
    }
}

.upfront-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.row {
    display: flex;
    flex-grow: 1;
}

.column {
    display: flex;
    flex-direction: column;
}

.filter {
    display: flex;
    .input-group {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        label {
            margin-bottom: 5px;
        }
    }

    .btn:not(:last-child) {
        margin-right: 30px;
    }

    .buttons {
        display: flex;
        align-items: flex-end;
        margin-left: auto;
    }

    @media screen and (max-width: 1050px) {
        & {
            flex-direction: column;

            .input-group {
                margin: 10px 0;
            }
        }
    }

    @media screen and (max-width: 405px) {
        .buttons {
            flex-direction: column;
            .btn {
                margin: 10px 0;
            }
        }
    }
}

.input {
    border: none;
    outline: none;
    background-color: #F3F6F9;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    &:focus {
        background-color: #eef3f8;
    }
}

.input-outline {
    border: 1px solid #707070;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'RobotoLight', Arial, Helvetica, sans-serif;
    padding: 5px;
    outline: none;
    background: none;
}

.veterinarios {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;

    .veterinario {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
        border-radius: 5px;
        width: 415px;
        margin: 20px;
        padding: 0;

        @media screen and (max-width: 860px) {
            margin-left: 0;
            margin-right: 0;
        }

        .info {
            margin: 18px;
            flex-grow: 1;
            text-align: center;

            a {
                text-decoration: none;
            }

            * {
                margin-bottom: 7px;
            }

            .sociais {
                a {
                    color: #474747;
                    margin-right: 10px;
                    font-size: 28px;
                }
            }
        }
        .loc {
            text-decoration: none;
            color: #474747;
            text-align: right;
            padding: 18px;
            background-image: url("./assets/map-close.png");
            background-size: 100% 100%;
            width: 160px;
            .logradouro {
                max-width: 170px;
            }
        }
        .descricao {
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            padding: 10px;
            max-width: 400px;
            height: 55px;
        }

        @media screen and (max-width: 480px) {
            width: 100%;

            .row {
                flex-direction: column;
            }

            .loc {
                text-align: center;
                width: unset;
            }

            .info {
                margin-bottom: 0;
            }

            .descricao {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .header header .logo {
        height: 30px;
    }
    .hello {
        opacity: 0;
    }
}


@media screen and (max-width: 600px) {
    .responsive {
        width: 100%;
        overflow: auto;

        .table {
            width: auto;
            min-width: 600px;
        }
    }
}
