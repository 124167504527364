.login {
    background-image: url("../../assets/dots-background.jpg");
    width: 100vw;
    height: 100vh;

    .brand {
        margin-bottom: 35px;

        img {
            width: 200px;
        }

        h1 {
            font-family: 'RobotoLight';
            border-top: 1px solid #474747;
            color: #474747;
            padding-top: 10px;
            font-size: 18px;
            text-align: center;
            font-weight: 100;
            text-transform: uppercase;
        }
    }

    .desc {
        color: #474747;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;

        h2 {
            margin-bottom: 10px;
            font-weight: normal;
            font-size: 18px;
        }

        span {
            font-weight: lighter;
            opacity: .6;
            font-size: 14px;
        }
    }

    form {
        width: 100%;
        max-width: 370px;

        @media screen {
            & {
                width: 80%;
            }
        }
    }

    .input {
        width: 100%;
        margin-bottom: 30px;
        font-family: inherit;
        font-weight: lighter;
        color: #474747;
        font-size: 16px;
        background-color: #F1F6FC;
        border-radius: 25px;
        padding: 15px 10px 15px 25px;
        border: none;
        outline: none;
        box-sizing: border-box;

        &::placeholder {
            color: #95989C;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    button {
        padding: 10px 40px;
    }
}
